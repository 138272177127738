import '../styles/application.scss';
import '../src/govukFrontendAssets';
import '../src/hmrcFrontendAssets';
import { initAll } from 'govuk-frontend/dist/govuk/govuk-frontend.min';
import hmrcFrontend from 'hmrc-frontend/hmrc/all';
import { enableGoogleAnalytics } from '../src/cookies/googleAnalytics';
import cookiesBanner from '../src/cookies/cookiesBanner';

require('@rails/ujs').start();

document.body.classList.add('js-enabled');
document.body.classList.add('noModule' in HTMLScriptElement.prototype ? 'govuk-frontend-supported' : '');

initAll();
hmrcFrontend.initAll();

enableGoogleAnalytics();
cookiesBanner();
